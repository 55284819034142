import "../styles/monitoring.css";
import { Col, Row, Table } from "antd";
import React, { useEffect, useState } from "react";
import "../styles/map.css";
import Uzbekistan from "./CustomSVGMap";
import { SVGMap } from "react-svg-map";
import axios from "axios";
import { toast } from "react-toastify";
import Stomp from "stompjs";
import SockJS from "sockjs-client";
import markaz from "../images/markaz.png";
import hhm from "../images/hhm.png";
import markaziy from "../images/markaziy.png";
import maxsus from "../images/maxsus.png";
import sharqiy from "../images/sharqiy.png";
import shimoliy from "../images/shimoliy garbiy.png";
import toqqq from "../images/toqqq.png";
import toshkent from "../images/Toshkent.png";
import mysound from "./sounds/alert.mp3"


function Monitoring() {
    const [dataList, setDataList] = useState();
    const [dataTypeArmy, setDataTypeArmy] = useState();
    const [dataRegionCode, setDataRegionCode] = useState();
    const [loading, setLoading] = useState(true);
    const [dataId, setDataId] = useState();
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
    const [blinkingElement, setBlinkingElement] = useState(null); // Элемент для мигания


    // Load the warning sound
    const warningSound = new Audio(mysound); // Add your sound file path here

    const formatTime = (timestamp) => {
        const date = new Date(parseInt(timestamp));
        if (isNaN(date.getTime())) {
            return "Invalid Date";
        }
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
        };
        return date.toLocaleString("uz-UZ", options);
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "specialId",
            key: "specialId",
            render: (text, record) => (
                <span style={{color: "darkred", fontFamily:"monospace"}}>
                    <b>{(record.specialId)}</b>
                </span>
            ),
        },
        {
            title: "MAC addressi",
            dataIndex: "macAddress",
            key: "macAddress",
            render: (text, record) => (
                <span style={{color: "darkred", fontFamily:"monospace"}}>
                    <b>{(record.macAddress)}</b>
                </span>
            ),
        },
        {
            title: "Bog`lanish turi",
            dataIndex: "type",
            key: "type",
            render: (text, record) => (
                <span style={{color: "darkred", fontFamily:"monospace"}}>
                    <b>{(record.type)}</b>
                </span>
            ),
        },
        {
            title: "Bog`lanish vaqti",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (text, record) => (
                <span style={{color: "darkred", fontFamily:"monospace"}}>
                    <b>{formatTime(record.createdAt)}</b>
                </span>
            ),
        },
    ];

    const updateTime = () => {
        setCurrentTime(new Date().toLocaleTimeString());
    };

    const onLocationClick = (event) => {
        console.log(event.target.id);
    };

    const getDataList = () => {
        axios
            // .get("http://192.168.202.41:8081/api/observatory/log/logbesh", {
            .get("https://security.observator.uz/api/observatory/log/logbesh", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                setDataList(res.data.object);
                console.log(res.data.object);
            })
            .catch((e) => {
                toast.error("Bog'lanishda xatolik!");
            });

        axios
            // .get("http://192.168.202.41:8081/api/observatory/log/count", {
            .get("https://security.observator.uz/api/observatory/log/count", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                setDataTypeArmy(res.data.object);
                setLoading(false);
                console.log(res.data.object);
            })
            .catch((e) => {
                toast.error("Bog'lanishda xatolik!");
            });
    };

    useEffect(() => {
        getDataList();
        const elementToChange = document.getElementById(dataRegionCode);
        if (elementToChange) {
            elementToChange.style.fill = "darkred";
        }
    }, [dataId, dataRegionCode]);

    setInterval(updateTime, 1000);

    useEffect(() => {
        // const socket = new SockJS("http://192.168.202.41:8081/our-websocket");
        const socket = new SockJS("https://security.observator.uz/our-websocket");
        const stompClient = Stomp.over(socket);

        stompClient.connect({}, (frame) => {
            console.log("Connected: " + frame);

            stompClient.subscribe("/topic/test", (message) => {
                const messageBodyObject = JSON.parse(message.body);
                console.log(messageBodyObject);
                setDataId(messageBodyObject);
                warningSound.play();
                toast.error("Yangi kiberxavfsizlik hodisasi sodir etildi!", {
                    position: "bottom-left",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });

                axios
                    // .get("http://192.168.202.41:8081/api/observatory/log/logRegionCode", {
                    .get("https://security.observator.uz/api/observatory/log/logRegionCode", {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    })
                    .then((res) => {
                        console.log(res.data.object);
                        switch (parseInt(res.data.object)) {
                            case 21:
                                setBlinkingElement("surxondaryo");
                                break;
                            case 96:
                                setBlinkingElement("bukhara");
                                break;
                            case 68:
                                setBlinkingElement("andijan");
                                break;
                            case 47:
                                setBlinkingElement("fergana");
                                break;
                            case 16:
                                setBlinkingElement("namangan");
                                break;
                            case 23:
                                setBlinkingElement("tashkent-case-23");
                                break;
                            case 62:
                                setBlinkingElement("tashkent-case-62");
                                break;
                            case 84:
                                setBlinkingElement("sirdaryo");
                                break;
                            case 92:
                                setBlinkingElement("qashqadaryo");
                                break;
                            case 49:
                                setBlinkingElement("jizzakh");
                                break;
                            case 18:
                                setBlinkingElement("samarqand");
                                break;
                            case 75:
                                setBlinkingElement("navoiy");
                                break;
                            case 20:
                                setBlinkingElement("karakalpakstan");
                                break;
                            case 32:
                                setBlinkingElement("xorazm");
                                break;
                            default:
                                setBlinkingElement(null);
                        }
                    })
                    .catch((err) => {
                        toast.error("Bog`lanishda xatolik.");
                    });
            });
        });

        return () => {
            if (stompClient) {
                stompClient.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (blinkingElement) {
            let isRed = true;
            const interval = setInterval(() => {
                const elementToBlink = document.getElementById(blinkingElement);
                if (elementToBlink) {
                    elementToBlink.style.fill = isRed ? "#111f64" : "darkred";
                    isRed = !isRed;
                }
            }, 500);

            return () => clearInterval(interval);
        }
    }, [blinkingElement]);



    return (
        <div className="back">
            <Row gutter={[8, 8]}>
                <Col span={14} className="text-center">
                    <div>
                        <div>
                            <div className="mt-2">
                                <h2 className="clock"  style={{ fontFamily: "monospace" }}> Mahalliy vaqt: &nbsp; {currentTime}</h2>
                                <div>
                                    <Row>
                                        <Col span={1}> </Col>
                                        <Col span={16}>
                                            <SVGMap onLocationClick={onLocationClick} map={Uzbekistan} />
                                        </Col>
                                        <Col span={1}> </Col>
                                        <Col span={6} className="text-start" style={{fontSize:"20px"}}>
                                            {dataId && (
                                                <>
                                                    <p style={{ fontFamily: "monospace"}}>
                                                        <b>ID:</b> {dataId.specialId}
                                                    </p>
                                                    <p style={{ fontFamily: "monospace" }}>
                                                        <b>MAC:</b> {dataId.macAddress}
                                                    </p>
                                                    <p style={{ fontFamily: "monospace" }}>
                                                        <b>Tur:</b> {dataId.type}
                                                    </p>
                                                    <p style={{ fontFamily: "monospace" }}>
                                                        <b>Hudud:</b> {dataId.region}
                                                    </p>
                                                    <p style={{ fontFamily: "monospace" }}>
                                                        <b>Vaqti:</b> {formatTime(dataId.createdAt)}
                                                    </p>
                                                </>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={10} className="text-center" style={{ fontFamily: "monospace" }}>
                    <div>
                        <div>
                            <div className="mt-2">
                                <h2 className="clock"> Hodisalar monitoringi</h2>
                                <div>
                                    <Row className="text-start mt-3">
                                        <Col span={24}>
                                            {loading ? (
                                                <p className="textt font-monospace fw-bold fs-1 ms-3 mt-3">
                                                    Ma'lumot yuklanmoqda...
                                                </p>
                                            ) : (
                                                <div>
                                                    <div>
                                                        {dataTypeArmy && (
                                                            <div className="row mt-1">
                                                                {/* First column */}
                                                                <div className="col">
                                                                    {dataTypeArmy.slice(0, 4).map((item) => (
                                                                        <div key={item.id} className="d-flex align-items-center mb-1">
                                                                            {/* Image */}
                                                                            {item.id === 1 && <img width="90px" src={markaz} alt="Image 1" />}
                                                                            {item.id === 2 && <img width="90px" src={toshkent} alt="Image 2" />}
                                                                            {item.id === 3 && <img width="90px" src={markaziy} alt="Image 2" />}
                                                                            {item.id === 4 && <img width="90px" src={maxsus} alt="Image 2" />}
                                                                            {/* Text */}
                                                                            <div className="ms-2 w-100">
                                                                                <p className="m-0 text-center font-monospace fw-bold fs-4">{item.name}</p>
                                                                                <p className="m-0 text-center font-monospace fw-bold fs-2">{item.count}</p>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                {/* Second column */}
                                                                <div className="col">
                                                                    {dataTypeArmy.slice(4, 8).map((item) => (
                                                                        <div key={item.id} className="d-flex align-items-center mb-1">
                                                                            {/* Image */}
                                                                            {item.id === 5 && <img width="90px" src={shimoliy} alt="Image 1" />}
                                                                            {item.id === 6 && <img width="90px" src={sharqiy} alt="Image 2" />}
                                                                            {item.id === 7 && <img width="90px" src={hhm} alt="Image 2" />}
                                                                            {item.id === 8 && <img width="90px" src={toqqq} alt="Image 2" />}
                                                                            {/* Text */}
                                                                            <div className="ms-2 w-100">
                                                                                <p className="m-0 text-center font-monospace fw-bold fs-4">{item.name}</p>
                                                                                <p className="m-0 text-center font-monospace fw-bold fs-2">{item.count}</p>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={24}  className="p-2 text-center" style={{ fontFamily: "monospace" }}>
                            <h2  className="text-center clock" > So'nggi sodir etilgan kiberxavfsizlik hodisalari </h2>
                            <Table columns={columns} dataSource={dataList} pagination={false} size="small" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}

export default Monitoring;
